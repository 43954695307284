<template>
  <div
    :class="[
      {
        'w-100': isLottie,
        'rounded rounded-lg overflow-hidden': props.rounded,
      },
    ]"
  >
    <Lottie
      v-if="isLottie"
      :blok="{
        ...blok,
        lottie: blok.image,
        ...utils.lottieProps(blok),
        text: '',
      }"
    />
    <NuxtImg
      v-else-if="blok?.image?.filename"
      sizes="sm:200px md:400px lg:700px"
      :src="blok.image.filename"
      :alt="blok.image?.alt"
      class="inline-block"
      :class="[size]"
      :style="blok?.image_style"
    />
  </div>
</template>

<script setup>
const props = defineProps({ blok: Object, rounded: Boolean });
const utils = useUtils();

const size = computed(
  () => `size-${props.blok?.icon_size || props.blok?.image_size}`,
);
// const size = computed(() => utils.iconSize(props.blok?.icon_size));
const isLottie = utils.isLottie(props.blok.image?.filename);
</script>

<style scoped>
.size-x-small {
  height: 20px;
}
.size-small {
  height: 40px;
}
.size-medium {
  height: 60px;
}
.size-large {
  height: 100px;
}
</style>
